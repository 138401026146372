<script>
  export let embed;
</script>


<img src={embed.image.url} alt={embed.image.url}>


<style>
  img {
    width: 100%;
    display: block;
    margin-top: 8px;
    border-radius: 4px;
  }
</style>